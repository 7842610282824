<template>
  <div class="mt-5">
    <h5 class="text-center">
      <span>{{ type | translate }} </span>
      <span>{{ "تسجيلات" | translate }} </span>
    </h5>
    <p class="text-center">{{ title | translate }}</p>
    <line-chart
      :chart-data="datacollection"
      :options="{ responsive: true, maintainAspectRatio: false }"
    />
    <hr />
    <div v-if="excelData" class="center">
      <download-excel
        class="btn btn-primary"
        :data="excelData"
        :header="[type, title]"
        :worksheet="label + '--' + title"
        :name="title + '.xls'"
      >
        Download Excel
      </download-excel>
    </div>
  </div>
</template>

<script>
export default {
  props: ["result", "title", "type", "label", "element"],
  data() {
    return {
      datacollection: {
        dashData: {},
        labels: ["", ...this.result.map((res) => res._id)],
        datasets: [
          {
            label: this.label,
            backgroundColor: "#5cae88d4",
            data: [0, ...this.result.map((res) => res.count)],
          },
          {
            label: this.element,
            backgroundColor: "#4f8bc9d4",
            data: [0, ...this.result.map((res) => res.sum)],
            hidden: true,
            // fill: false,
            // borderColor: "#4f8bc9d4",
            // pointBackgroundColor: "transparent",
            // borderDash: [20, 30],
          },
        ],
      },
    };
  },
  computed: {
    excelData() {
      if (!this.result) return [];
      return this.result.map((res) => {
        return {
          [this.type]: res._id,
          count: res.count,
          [this.element]: res.sum,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
</style>
