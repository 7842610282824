<template>
  <div class="container">
    <h1 class="text-center">{{ "Statistics" | translate }}</h1>
    <div class="filters">
      <div class="row g-3 justify-content-center align-items-end" dir="rtl">
        <div class="col-md-3">
          <label>{{ "نوع المعدة" | translate }}</label>
          <select v-model="filter.category" class="form-control">
            <option :value="null">{{ "كل المعدات" | translate }}</option>
            <option
              v-for="category in categories"
              :key="category._id"
              :value="category.name"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <!------------------------>

        <div class="col-md-3">
          <label>{{ "الموقع" | translate }}</label>
          <!-------location-fix--->
          <select v-model="filter.location" class="form-control">
            <option :value="null">{{ "كل المواقع" | translate }}</option>
            <option
              v-for="location in locations"
              :key="location.name"
              :value="location.name"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
        <!--------------------------->
        <div class="col-md-3">
          <label>{{ "كود المعدة" | translate }}</label>
          <input type="text" class="form-control" v-model="filter.code" />
        </div>
        <!---------------------------->
        <div class="col-md-3">
          <label>{{ "الحالة" | translate }}</label>
          <select v-model="filter.state" class="form-control">
            <option :value="null">{{ "كل الحالات" | translate }}</option>
            <option v-for="state in states" :key="state.code" :value="state.code">
              {{ state.desc }}
            </option>
          </select>
        </div>
        <!--------------------------->
        <div class="col-md-3">
          <label>{{ "من" | translate }}</label>
          <input type="date" class="form-control" v-model="filter.from" />
        </div>
        <!--------------------------->
        <div class="col-md-3">
          <label>{{ "الى" | translate }}</label>
          <input type="date" class="form-control" v-model="filter.to" />
        </div>
        <!---------------------------->
        <div class="col-md-3 center">
          <button @click="filterRecords" class="btn btn-primary">
            {{ "filter" | translate }}
          </button>
        </div>
      </div>
      <hr />
    </div>
    <br /><br />
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->

    <!-- <div v-if="recordsLoading" class="center">
      <loading />
    </div> -->
    <div class="records">
      <h2 class="text-center">{{ "سجل المتابعة للمعدة" | translate }}</h2>
      <div class="center">
        <app-table
          title=""
          :loading="recordsLoading"
          :items="records"
          :colsNames="[
            'التاريخ',
            'الحالة',
            'الأجراء',
            'الآفَة',
            'المعدة',
            'الكود',
            'الموقع',
            'الموظف',
          ]"
          :cols="[
            'date',
            'state',
            'procedure',
            'element',
            'category',
            'code',
            'location',
            'employeeName',
          ]"
          :btns="[
            {
              icon: 'pencil',
              method: (item) => $router.push(`/edit-record/${item._id}`),
            },
          ]"
          color="#1a3930cc"
        />
      </div>
      <div v-if="records.length > 0" class="center">
        <download-excel
          class="btn btn-primary"
          :data="computedRecords"
          :header="['records', chartTitle]"
          :worksheet="'records' + '--' + chartTitle"
          :name="chartTitle + '.xls'"
        >
          Download Excel
        </download-excel>
      </div>
      <hr />
    </div>

    <!-------------------------------------------------------->
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->

    <div>
      <result-chart
        v-if="categoryChart.length > 0"
        :result="categoryChart"
        :title="chartTitle"
        :element="catElement"
        label="categories records"
        type="categories"
      />
      <br /><br />
      <!---------------------------------------------------->
      <!---------------------------------------------------->
      <result-chart
        v-if="locationChart.length > 0"
        :result="locationChart"
        :title="chartTitle"
        :element="catElement"
        label="Locations records"
        type="Locations"
      />
      <br /><br />
      <!---------------------------------------------------->
      <!---------------------------------------------------->
      <result-chart
        v-if="codeChart.length > 0"
        :result="codeChart"
        :title="chartTitle"
        :element="catElement"
        label="Records of this equip codes"
        type="codes"
      />
      <br /><br />
      <!---------------------------------------------------->
      <!---------------------------------------------------->
      <result-chart
        v-if="stateChart.length > 0"
        :result="stateChart"
        :title="chartTitle"
        :element="catElement"
        label="records of equips  status"
        type="status"
      />
      <br /><br />
      <!---------------------------------------------------->
      <!---------------------------------------------------->
      <result-chart
        v-if="dateChart.length > 0"
        :result="dateChart"
        :title="chartTitle"
        :element="catElement"
        label="Records in this day"
        type="Days"
      />
      <!---------------------------------------------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ResultChart from "./result-chart.vue";
export default {
  components: {
    ResultChart,
  },
  mounted() {
    /////// location-fix /////////////
    // if (this.auth.role != 2) this.filter.location = this.auth.location;
  },
  data() {
    return {
      filter: {
        category: null,
        location: null,
        code: null,
        from: null,
        to: null,
      },
      records: [],
      recordsLoading: false,
      ///////////////////////
      categoryChart: [],
      locationChart: [],
      codeChart: [],
      stateChart: [],
      dateChart: [],
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    locations() {
      /////// location-fix /////////////
      if (this.auth.role == 2) {
        return this.$store.getters.locations;
      } else if (this.auth.role == 1) {
        return this.auth.location.map((loc) => ({ name: loc }));
      }
      return [];
    },
    states() {
      const deviceCat = this.filter.category;
      if (!deviceCat) return [];
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category?.states;
    },
    groupingItems() {
      const items = [];
      if (!this.filter.category) items.push("category");
      if (!this.filter.location) items.push("location");
      if (!this.filter.code) items.push("code");
      if (!this.filter.state) items.push("state");
      if (this.filter.from && this.filter.to) items.push("date");
      if (this.filter.from && !this.filter.to) items.push("date");
      return items;
    },
    catElement() {
      const deviceCat = this.filter.category;
      if (!deviceCat) return "pets";
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category.element == "0" ? "pets" : category.element;
    },
    chartTitle() {
      let title = "";
      if (this.filter.category) title += `category (${this.filter.category})\n`;
      if (this.filter.location) title += `location (${this.filter.location})\n`;
      if (this.filter.code) title += `code (${this.filter.code})\n`;
      if (this.filter.state) title += `status (${this.filter.state})\n`;
      ////////////
      if (!this.filter.from && !this.filter.from) title += `today`;
      else if (!this.filter.from || this.filter.from == this.filter.to) title += `today`;
      else if (this.filter.from && !this.filter.to)
        title += `from (${this.filter.from}) till today`;
      else {
        if (this.filter.from) title += `from (${this.filter.from})\n`;
        if (this.filter.to) title += `to (${this.filter.to})`;
      }
      return title;
    },
    auth() {
      return this.$store.getters.auth;
    },
    computedRecords() {
      if (this.records.length == 0) return [];
      return this.records.map((rec) => {
        return {
          equipment: rec.category,
          location: rec.location,
          code: rec.code,
          state: rec.state,
          procedure: rec.procedure,
          date: rec.date,
          employee: rec.employeeName,
        };
      });
    },
  },
  methods: {
    filterRecords() {
      this.categoryChart = [];
      this.locationChart = [];
      this.codeChart = [];
      this.stateChart = [];
      this.dateChart = [];
      /////////////////////////////////////
      this.recordsLoading = true;
      axios.post("/filterRecords", this.filter).then((res) => {
        if (res.data.err) return alert(res.data.err);
        this.recordsLoading = false;
        const records = res.data.records;
        this.records = records.map((rec) => {
          return {
            ...rec,
            state: `${rec.stateName} (${rec.state})`,
            procedure: `${rec.procedureName} (${rec.procedure})`,
          };
        });
        this.groupingItems.forEach((group) => {
          this.filterGroupAndSum(group);
        });
      });
    },
    /////////////////////////////////////////

    filterGroupAndSum(group) {
      console.log(this.filter);
      this.recordsLoading = true;
      axios
        .post("/filterGroupAndSumRecords", {
          filter: this.filter,
          group,
        })
        .then((res) => {
          if (res.data.err) return alert(res.data.err);
          this.recordsLoading = false;
          const records = res.data.records;
          if (group == "category") this.categoryChart = records;
          if (group == "location") this.locationChart = records;
          if (group == "code") this.codeChart = records;
          if (group == "state") this.stateChart = records;
          if (group == "date") this.dateChart = records;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
